import React from "react";

const TeamSection = () => {
  const commonStyle = {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
    opacity: 1,
  };

  // Individual team item component
  const TeamItem = ({
    imageUrl,
    name,
    position,
    fbLink,
    twitterLink,
    instagramLink,
  }) => (
    <div className="team-item" style={commonStyle}>
      <img src={imageUrl} loading="lazy" alt="" className="team-thumbnail" />
      <div
        className="team-info-holder"
        style={{ ...commonStyle, transform: "translate3d(0px, 100%, 0px)" }}
      >
        <div
          className="team-info-container"
          style={{ ...commonStyle, transform: "translate3d(0px, 118px, 0px)" }}
        >
          <div className="name-holder">
            <div className="name">{name}</div>
          </div>
          <div className="name-and-description">
            <div className="job-position">{position}</div>
          </div>
        </div>
        <div
          className="team-social-media"
          style={{ ...commonStyle, transform: "translate3d(0px, 103px, 0px)" }}
        >
          <div className="social-media-container">
            <a
              href={fbLink}
              target="_blank"
              className="social-media-holder w-inline-block"
            >
              {/* Facebook Icon */}
            </a>
            <a
              href={twitterLink}
              target="_blank"
              className="social-media-holder w-inline-block"
            >
              {/* Twitter Icon */}
            </a>
            <a
              href={instagramLink}
              target="_blank"
              className="social-media-holder w-inline-block"
            >
              {/* Instagram Icon */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  const fadeInStyle = {
    transform:
      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
    transformStyle: "preserve-3d",
    opacity: 1,
  };

  return (
    <div className="section">
      <div className="value-team-holder">
        <div className="container">
          <div className="center-text">
            <div className="break-down-heading-holder">
              <div className="fade-in-on-scroll" style={fadeInStyle}>
                <h2>Meet our team</h2>
              </div>
            </div>
            <div className="break-down-paragraph-holder">
              <div className="fade-in-on-scroll" style={fadeInStyle}>
                <p>
                  Our core team is comprised of a diverse group of experts in
                  AI, SaaS and software development.
                </p>
              </div>
            </div>
          </div>
          <div className="team-gird-holder">
            <div className="w-layout-grid team-grid">
              {/* Example Team Items */}
              <TeamItem
                imageUrl="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/1b064cac-fa34-40d8-c09e-ca9afa0f1e00/full"
                name="Emre Unvanli"
                position="CEO"
                fbLink="http://fb.com"
                twitterLink="http://twitter.com"
                instagramLink="http://instagram.com"
              />
              <TeamItem
                imageUrl="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/0a65b7d9-f694-4bbc-dfff-10f924b0e500/full"
                name="Hakan Karakaaya"
                position="CTO"
                fbLink="http://fb.com"
                twitterLink="http://twitter.com"
                instagramLink="http://instagram.com"
              />
              <TeamItem
                imageUrl="https://imagedelivery.net/DkqIgfg5yEUtrJi2ZtJ9uA/03880429-cf9a-42ab-6ba0-aec773a6eb00/full"
                name="Canberk Arslan"
                position="CMO"
                fbLink="http://fb.com"
                twitterLink="http://twitter.com"
                instagramLink="http://instagram.com"
              />
              {/* Add more TeamItem components as needed */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
